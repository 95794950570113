@media screen and (max-height: 1080px) {
  html {
    font-size: clamp(14px,  1vw, 16px);
  }
}

@media screen and (min-height: 1081px) {
  html {
    font-size: clamp(20px, 1vw, 24px);
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --topbar-height: 6rem;
  --max-image-width: 27rem;
}

th {
  text-align: left;
  padding: 8px 0
}

td {
  padding: 4px 0;
}

form {
  height: 100%;
}

button:disabled,
button[disabled]{
  background-color: #666666;
  color: #cccccc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}
